import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';
import { createDynamicURL } from '../../utils/dynamicParams';
// ----------------------------------------------------------------------
const initialState = {
    status: 'idle',
    rentableTypes: [],
};
// ----------------------------------------------------------------------
export const getRentableTypes = createAsyncThunk('vehicle/getRentableTypes', async ({ isExtra = '0', agencyIds = '', isPaging = false }) => {
    let data;
    const queryParams = {
        search: {
            isExtra,
            'class.agency.id': agencyIds,
        },
        isPaging,
    };
    try {
        const url = createDynamicURL('/rentableTypes', queryParams);
        const response = await axios.get(url);
        data = await response.data;
        if (response.status === 200) {
            return data.data;
        }
        throw new Error(response.statusText);
    }
    catch (err) {
        return Promise.reject(err.message ? err.message : data?.message);
    }
});
const slice = createSlice({
    name: 'rentableType',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRentableTypes.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(getRentableTypes.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.rentableTypes = action.payload;
        })
            .addCase(getRentableTypes.rejected, (state, action) => {
            state.status = 'failed';
        });
    },
});
// Reducer
export const reducer = slice.reducer;
export default slice;
